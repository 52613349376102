import request from 'axios'

const version = '4.13.0'

export function goWithList(params) {
  return request({
    url: '/escort_new/newGroupList',
    method: 'get',
    params
  })
}

export function hotRouteList(params) {
  return request({
    url: '/tourcloud/tourcloud/getArticleVoList',
    method: 'get',
    params
  })
}

export function groupList(params) {
  return request({
    url: 'member/chat/group/list',
    method: 'get',
    params,
    headers: {
      'Cuttlefish-Version': version,
    }
  })
}

export function tourCustom(data) {
  return request({
    url: '/tourcloud/tourCustom/save',
    method: 'post',
    data
  })
}
export function articleTagList(params) {
  return request({
    url: '/base/article/getArticleList/',
    method: 'get',
    params
  })
}
export function articleList(params) {
  return request({
    url: '/base/article/getArticleList/v2',
    method: 'get',
    params
  })
}