<template>
	<div class="home-header">
		<div class="home-header-container">
			<img
				class="logo"
				src="https://oss.cuttlefish.vip/file/backstageManagement/serveManage/1625640728284.png"
				@click="routerJump('/')"
			/>
			<img
				v-if="!opened"
				class="menu-btn"
				src="@/assets/images/header/menu-open.png"
				@click="opened = true"
			/>
			<img
				v-else
				class="menu-btn"
				src="@/assets/images/header/menu-close.png"
				@click="opened = false"
			/>
		</div>
		<div class="nav-menu" v-show="opened">
			<div v-for="(menu, index) in menuList" :key="index">
				<div
					class="nav-menu-item"
					:style="{ color: path === menu.path ? '#26A1FF' : '' }"
					@click="routerJump(menu.path)"
				>
					{{ menu.name }}
				</div>
			</div>
		</div>
	</div>
</template>
<script setup>
	export default {
		data() {
			return {
				menuList: [
					{ id: 1, name: "首页", path: "/home" },
					{ id: 2, name: "定制游", path: "/customTour" },
					{ id: 3, name: "驴友社区", path: "/travelCommunity" },
					{ id: 4, name: "组队", path: "/goWith" },
					{ id: 5, name: "热门路线", path: "/hotRoute" },
					{ id: 6, name: "APP下载", path: "/appDownload" },
				],
				opened: false,
				path: this.$route.path,
			};
		},
		methods: {
			routerJump(path) {
				this.$router.push(path);
				this.opened = false;
			},
		},
	};
</script>
<style scoped lang="scss">
	.home-header {
		z-index: 1000;
		width: 100%;
		background-color: #ffffff;
		color: #3d3d3d;
		box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.08),
			0px 0px 100px 5px rgba(0, 0, 0, 0.16);

		.home-header-container {
			max-width: 1440px;
			display: flex;
			align-items: center;
			padding: 21px 16px;
			box-sizing: border-box;
			margin: 0 auto;
			z-index: 1000;
			justify-content: space-between;
			height: 64px;
			box-sizing: border-box;
			.logo {
				width: 91px;
			}
			.menu-btn {
			}
		}

		.nav-menu {
			position: absolute;
			top: 63px;
			background-color: #ffffff;
			width: 100%;
			z-index: 99;
			.nav-menu-item {
				width: 100%;
				text-align: center;
				box-sizing: border-box;
				font-size: 12px;
				font-weight: 500;
				line-height: normal;
				letter-spacing: 0em;
				font-feature-settings: "kern" on;
				color: #3d3d3d;
				margin-bottom: 20px;
			}
		}
	}
</style>
